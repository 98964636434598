import React from "react";
import {graphql, Link} from "gatsby"
import styled from "styled-components"

import "./card.scss";

const Card = ({data}) => (
    <div className="card-container" id="projekte">
        <Link to={`/works/${data.slug}`}>
            <StyledCard backgroundColor={data.backgroundColor.hex} color={data.textColor.hex}>
                <StyledTextContainer>
                    <StyledTitle color={data.textColor}>{data.title}</StyledTitle>
                    <p>{data.description}</p>
                </StyledTextContainer>
                <StyledImageContainer>
                    <StyledCoverImage src={data.coverImage.fluid.src} alt=""/>
                </StyledImageContainer>
            </StyledCard>
        </Link>
    </div>
);

export default Card;

const StyledTextContainer = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledImageContainer = styled.div`
  width: 50%;
  text-align: right;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledCard = styled.div`
  display: flex;
  align-items: center;
  height: 27rem;
  padding: 3rem;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  transition: 0.3s;
  
  :hover {
    transform: scale(1.05);
    transition: 0.3s;
  }
   @media (max-width: 480px) {
    padding: 1.5rem;
    width: 100%;
    flex-direction: column;
  }
`;

const StyledTitle = styled.h1`
  color: ${props => props.color};
`;

const StyledCoverImage = styled.img`
   width: 100%;
`;

export const query = graphql`
    fragment card on DatoCmsWork {
        id
        title
        excerpt
        description
        coverImage {
            fluid(maxWidth: 600, imgixParams: { fm: "png", auto: "compress" }) {
                ...GatsbyDatoCmsSizes
            }
        }
    }
`