import React from 'react'
import styled from "styled-components";

const Footer = () => {
    const today = new Date();
    const date = today.getFullYear()

    return (
        <StyledFooter>
            <p>© {date} Portfolio - Severin Kilchhofer</p>
        </StyledFooter>);
}

export default Footer;

const StyledFooter = styled.footer`
  margin-top: 10%;
  color: black;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
`;